import React from "react"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { HeroMini } from "components/hero"
import { Section } from "components/section"
import PrivacyPolicyContent from "components/policies/PrivacyPolicyContent"

const heroProps = {
  title: <>Privacy Policy</>,
}

const PrivacyPolicy = (props) => (
  <Layout location={props.location}>
    <Meta title="Privacy Policy" />
    <HeroMini {...heroProps} />
    <Section noTopSpacing>
      <PrivacyPolicyContent />
    </Section>
  </Layout>
)

export default PrivacyPolicy
