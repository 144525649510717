import React from "react"
import cx from "classnames"
import s from "./Styles.module.scss"
import { SectionHeading } from "components/section"
import { Link } from "components/link"

const PrivacyPolicyContent = () => (
  <div className={s.wrapper}>
    <SectionHeading className={s.title} order="01">
      We care about your Personal&nbsp;data
    </SectionHeading>
    <p className={s.paragraph}>
      Data protection is our special interest. This privacy notice explains
      which personal data we collect, why we collect them, how we use them, with
      whom we share your personal data, how we keep it safe and which rights do
      you have. When we use your data, we use them according to this Privacy
      notice. This Privacy notice is related to our website visitors and job
      applicants. The term “We” or “Our” in this Privacy Notice refers to the
      network of Martian &amp; Machine firms which consists of the following
      legal entities:
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>
        Martian &amp; Machine GmbH, based in Germany, Karl-Liebknecht-Str. 29A,
        10178 Berlin
      </li>
      <li className={s.listItem}>
        Martian &amp; Machine d.o.o., based in Croatia, Ulica kneza Branimira
        41, 10 000 Zagreb
      </li>
    </ul>
    <p className={s.paragraph}>
      You can get more details about Martian &amp; Machine legal entities on{" "}
      <a href="/impressum/">this link</a>.
    </p>
    <p className={s.paragraph}>
      If you have any questions regarding your Personal Data, please feel free
      to contact us via e-mail:{" "}
      <a
        href="mailto:hello@martian.ventures"
        target="_blank"
        rel="noopener noreferrer"
      >
        hello@martian.ventures
      </a>{" "}
      or by post to our official address Karl-Liebknecht-Str. 29A, 10178 Berlin,
      Germany.
    </p>

    <SectionHeading className={s.title} order="02">
      What is Personal&nbsp;data?
    </SectionHeading>
    <p className={s.paragraph}>
      Personal data means any information relating to an identified or
      identifiable natural person ('data subject'); an identifiable natural
      person is one who can be identified, directly or indirectly, in particular
      by reference to an identifier such as a name, an identification number,
      location data, online identifier or to one or more factors specific to the
      physical, physiological, genetic, mental, economic, cultural or social
      identity of that natural person.
    </p>

    <SectionHeading className={s.title} order="03">
      And which Personal data we&nbsp;use?
    </SectionHeading>
    <p className={s.paragraph}>
      Depending on the purpose, we collect the following type of Personal data:
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>Your name and surname</li>
      <li className={s.listItem}>Contact details (email, telephone number)</li>
      <li className={s.listItem}>Address</li>
      <li className={s.listItem}>Date and place of birth</li>
      <li className={s.listItem}>
        Information about your activities on the website, IP address,
        geographical location, type of device{" "}
      </li>
      <li className={s.listItem}>
        Information about your education and skills
      </li>
      <li className={s.listItem}>
        Information about your previous work experience
      </li>
      <li className={s.listItem}>
        Information you provide us in the contact form
      </li>
      <li className={s.listItem}>
        Information contained in your CV or motivational letter
      </li>
      <li className={s.listItem}>
        Other information you voluntarily provide to us
      </li>
    </ul>

    <SectionHeading className={s.title} order="04">
      When do we collect your Personal&nbsp;data?
    </SectionHeading>
    <p className={s.paragraph}>We collect your Personal data:</p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>
        Directly from you (when you contact us or when you get interviewed)
      </li>
      <li className={s.listItem}>
        Indirectly (from third parties- employee agency)
      </li>
      <li className={s.listItem}>
        From public sources (Linked In and other public sources)
      </li>
      <li className={s.listItem}>
        Automatically (when you visit this website)
      </li>
      <li className={s.listItem}>
        From our website that uses cookies. Read more about cookies in the{" "}
        <Link to="/cookie-policy/">cookie policy</Link>.
      </li>
      <li className={s.listItem}>
        When you engage with us through social media (when you tag us, mention
        us or contact us through social media)
      </li>
    </ul>

    <SectionHeading className={s.title} order="05">
      Am I obligated to provide you with my Personal&nbsp;data?
    </SectionHeading>
    <p className={s.paragraph}>
      No, you are not obligated to provide us with your Personal data. However,
      where your Personal Data is required for us to manage recruitment process
      or to provide you with the information you have requested, we may not be
      able to offer you services or enter into a contract with you.
    </p>

    <SectionHeading className={s.title} order="06">
      Children’s Personal&nbsp;data
    </SectionHeading>
    <p className={s.paragraph}>
      Our website is not intended for minors. We advise parents/guardians to
      inform their children about the safety and use of personal data on the
      Internet.
    </p>

    <SectionHeading className={s.title} order="07">
      Why we collect your Personal&nbsp;data?
    </SectionHeading>
    <p className={s.paragraph}>
      We use your Personal Data for various purposes, which include:
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>
        sending you communication which you have requested{" "}
      </li>
    </ul>
    <p className={s.paragraph}>
      We use your data to provide you with the information about our services in
      the area in which you have expressed your interest through the contact
      web-form. It is our legitimate interest as a business to use your data for
      this purpose.{" "}
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>
        conduct fraud checks and prevent illegal activity
      </li>
    </ul>
    <p className={s.paragraph}>
      It is our legitimate interest as a business to use your data for this
      purpose.
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>to respond and defend against legal claims</li>
    </ul>
    <p className={s.paragraph}>
      It is our legitimate interest as a business to use your data for this
      purpose
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>operate our website</li>
    </ul>
    <p className={s.paragraph}>
      It is our legitimate interest as a business to use your data for this
      purpose
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>fulfilling our legal obligations</li>
    </ul>
    <p className={s.paragraph}>
      Sometimes we will use your personal data because it's necessary for us to
      comply with a legal obligation.
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>
        for the purposes of the recruitment process:
      </li>
      <li className={cx(s.listItem, s.listItemWrapper)}>
        <ul>
          <li className={s.listItem}>
            to conduct background (reference) checks of job applicants
            (verification of past employment, education, etc.)
          </li>
          <li className={s.listItem}>
            to assess your skills and qualifications for the offered/opened
            position
          </li>
          <li className={s.listItem}>
            to communicate with you about the recruitment process
          </li>
          <li className={s.listItem}>to decide to whom to offer the job</li>
        </ul>
      </li>
    </ul>
    <p className={cx(s.paragraph, s.paragraphIndented)}>
      It is our legitimate interest to decide whether to appoint you the role
      and to manage a recruitment process. Also, sometimes we need to process
      your data to take steps at your request prior to entering into a contract
      with you.
    </p>
    <ul className={s.unorderedList}>
      <li className={cx(s.listItem, s.listItemWrapper)}>
        <ul>
          <li className={s.listItem}>
            to communicate with you after the recruitment process
          </li>
        </ul>
      </li>
    </ul>
    <p className={cx(s.paragraph, s.paragraphIndented)}>
      If your application was unsuccessful, we would like to keep your Personal
      Data in order to communicate with you about future job opportunities. The
      legal base is your consent.{" "}
    </p>
    <SectionHeading className={s.title} order="08">
      Sharing your data with third&nbsp;parties
    </SectionHeading>
    <p className={s.paragraph}>We don’t sell or rent your Data.</p>
    <p className={s.paragraph}>
      In order to achieve the purposes, set out in this Policy, we share
      Personal data with third party which may include:
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>
        Third party service providers who work on our behalf or who we are
        working with (We work with carefully selected third parties, such as IT
        service providers, accountants, legal advisors, payment services, hire
        companies, etc.)
      </li>
      <li className={s.listItem}>
        Public authorities and other bodies governed by public law
      </li>
      <li className={s.listItem}>
        We may disclose your Personal Data to a third party as a part of a sale
        or merger or reorganization of our business assets.
      </li>
    </ul>
    <p className={s.paragraph}>
      We will always take care that your Personal data is protected.
    </p>

    <SectionHeading className={s.title} order="09">
      For how long we keep your&nbsp;data?
    </SectionHeading>
    <p className={s.paragraph}>
      We keep your Personal Data only as is necessary for the purpose it was
      collected. The retention period depends on the following criteria:
    </p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>
        In which purpose do we process personal data?
      </li>
      <li className={s.listItem}>
        What is a lawful basis for such processing?
      </li>
      <li className={s.listItem}>
        Are we legally required to hold some types of Personal Data?
      </li>
    </ul>

    <SectionHeading className={s.title} order="10">
      Data protection and transfers of personal data outside of&nbsp;EEA
    </SectionHeading>
    <p className={s.paragraph}>
      We take all necessary organizational and technical measures in order to
      protect your personal data. We store your Personal data inside the
      European Economic Area (EEA), except in the case when your personal data
      is stored on the servers in the country outside the EEA. For communicating
      with website visitors, we use a server which is in Germany. Please note
      that other countries may have different privacy regulations and standards
      than the EU. However, we will take care that your personal data is subject
      to the same safeguard measures as inside the EEA.
    </p>
    <SectionHeading className={s.title} order="11">
      Link to Social media
    </SectionHeading>
    <p className={s.paragraph}>
      Our website contains a link to social media (Facebook, Instagram,
      Dribbble, Github etc.). Please note that Facebook and Instagram have their
      own privacy rules, so we advise you to read Facebook or Instagram Privacy
      rules. We are not responsible for the privacy rules of the Facebook or the
      Instagram even if you access them through our link.
    </p>
    <SectionHeading className={s.title} order="12">
      Processing of (personal) data by the operator of the recruitment website
    </SectionHeading>
    <p className={s.paragraph}>
      <b>General information</b>
    </p>
    <p className={s.paragraph}>
      This recruitment website is operated by Personio GmbH, which offers a
      human resource and candidate management software solution{" "}
      <a
        href="https://www.personio.com/legal-notice"
        rel={"noopener noreferrer"}
      >
        (https://www.personio.com/legal-notice/)
      </a>
      . Data transmitted as part of your application will be transferred using
      TLS encryption and stored in a database. The sole controller of this data
      within the meaning of article 24 of the GDPR is the enterprise carrying
      out this online application process. Personio's role is limited to
      operating the software and this recruitment website and, in this context,
      being a processor under article 28 of the GDPR. In this case, the
      processing by Personio is based on an agreement for the processing of
      orders between the controller and Personio. In addition, Personio GmbH
      processes further data, some of which may be personal data, to provide its
      services, in particular for operating this recruitment website. We will
      refer to this in more detail below.
    </p>
    <p className={s.paragraph}>
      <b>The controller</b>
    </p>
    <p className={s.paragraph}>The controller under data protection law is:</p>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>Personio GmbH</li>
      <li className={s.listItem}>Rundfunkplatz 4</li>
      <li className={s.listItem}>80335 München</li>
      <li className={s.listItem}>Phone: +49 / 89 1250 1005</li>
      <li className={s.listItem}>
        Commercial register entry number: HRB 213189
      </li>
      <li className={s.listItem}>
        Registration Court: Amtsgericht München (Munich Local Court)
      </li>
      <li className={s.listItem}>
        Data Protection Officer contact: datenschutz@personio.de
      </li>
    </ul>
    <p className={s.paragraph}>
      <b>Access logs ("server logs")</b>
    </p>
    <p className={s.paragraph}>
      Each access to this recruitment website automatically causes general
      protocol data, so-called server logs, to be collected. As a rule, this
      data is a pseudonym and thus does not allow for inferences about the
      identity of an individual. Without this data, it would, in some cases, be
      technically impossible to deliver or display the contents of the software.
      In addition, processing this data is absolutely necessary under security
      aspects, in particular for access, input, transfer, and storage control.
      Furthermore, this anonymous information can be used for statistical
      purposes and for optimizing services and technology. In addition, the log
      files can be checked and analyzed retrospectively when unlawful use of the
      software is suspected. The legal basis for this is section 15 subsection 1
      of the German Telemedia Act (TMG), as well as article 6 (1) f of the GDPR.
      Generally, data such as the domain name of the website, the web browser
      and web-browser version, the operating system, the IP address, as well as
      the timestamp of the access to the software is collected. The scope of
      this log process does not exceed the common log scope of any other site on
      the web. These access logs are stored for a period of up to 7 days. There
      is no right to object to this.
    </p>

    <p className={s.paragraph}>
      <b>Error logs</b>
    </p>
    <p className={s.paragraph}>
      So-called error logs are generated for the purpose of identifying and
      fixing bugs. This is absolutely necessary to ensure we can react as
      quickly as possible to possible problems with displaying and implementing
      content (legitimate interest). As a rule, this data is a pseudonym and
      thus does not allow for inferences about the identity of an individual.
      The legal basis for this is section 15 subsection 1 of the German
      Telemedia Act (TMG), as well as article 6 (1) f) of the GDPR. When an
      error message occurs, general data such as the domain name of the website,
      the web browser and web-browser version, the operating system, the IP
      address, as well as the timestamp upon occurrence of the respective error
      message and/or specification is collected. These error logs are stored for
      a period of up to 7 days. There is no right to object to this.
    </p>

    <p className={s.paragraph}>
      <b>Rights of data subjects</b>
    </p>
    <p className={s.paragraph}>
      If Personio GmbH as the controller processes personal data, you as the
      data subject have certain rights under Chapter III of the EU General Data
      Protection Regulation (GDPR), depending on the legal basis and the purpose
      of the processing, in particular the right of access (article 15 of the
      GDPR) and the rights to rectification (article 16 of the GDPR), erasure
      (article 17 of the GDPR), restriction of processing (article 18 of the
      GDPR), and data portability (article 20 of the GDPR), as well as the right
      to object (article 21 of the GDPR). If the personal data is processed with
      your consent, you have the right to withdraw this consent under article 7
      III of the GDPR. To assert your rights as a data subject in relation to
      the data processed for the purpose of operating this recruitment website,
      please refer to Personio GmbH's Data Protection Officer (see item B).
    </p>

    <p className={s.paragraph}>
      <b>Concluding provisions</b>
    </p>
    <p className={s.paragraph}>
      Personio reserves the right to adjust this data privacy statement at any
      point in time to ensure that it is in line with the current legal
      requirements at all times, or in order to accommodate changes in the
      services offered, for example when new services are introduced. In this
      case, the new data privacy statement applies to any later visit of this
      recruitment website or any later job application.
    </p>

    <SectionHeading className={s.title} order="13">
      Your rights
    </SectionHeading>
    <ul className={s.unorderedList}>
      <li className={s.listItem}>
        Right to be informed about processing your Personal data
      </li>
      <li className={s.listItem}>Right to withdraw a consent</li>
      <li className={cx(s.listItem, s.listItemWrapper)}>
        <ul>
          <li className={s.listItem}>
            If we process your Personal Data based on the consent, you have the
            right at any time to withdraw your consent, by sending the email to{" "}
            <a
              href="mailto:hello@martian.ventures"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@martian.ventures
            </a>
          </li>
        </ul>
      </li>
      <li className={s.listItem}>
        Right of access to your Personal data or to ask for a copy of your
        Personal data
      </li>
      <li className={s.listItem}>Right to correct your Personal data</li>
      <li className={cx(s.listItem, s.listItemWrapper)}>
        <ul>
          <li className={s.listItem}>
            If your Personal Data that we hold is incorrect or unaccrued, you
            have the right to correct or up to date it
          </li>
        </ul>
      </li>
      <li className={s.listItem}>Right to erasure (“Right to be forgotten”)</li>
      <li className={cx(s.listItem, s.listItemWrapper)}>
        <ul>
          <li className={s.listItem}>
            This is not your absolute right and only applies in certain
            circumstances.
          </li>
        </ul>
      </li>
      <li className={s.listItem}>Right to restrict the use of Personal data</li>
      <li className={cx(s.listItem, s.listItemWrapper)}>
        <ul>
          <li className={s.listItem}>
            This is not an absolute right and only applies in certain
            circumstances.
          </li>
        </ul>
      </li>
      <li className={s.listItem}>
        Right for your information to be portable (Personal data portability)
      </li>
      <li className={cx(s.listItem, s.listItemWrapper)}>
        <ul>
          <li className={s.listItem}>
            The right to data portability only applies when your lawful basis
            for processing Personal data is consent or for the performance of a
            contract; and when you are carrying out the processing by automated
            means (i.e. excluding paper files)
          </li>
        </ul>
      </li>
      <li className={s.listItem}>
        Right to object in case that we are processing your personal data based
        on legitimate interest, for direct marketing or statistical/research
        purposes
      </li>
      <li className={s.listItem}>Right to lodge a complaint </li>
      <li className={cx(s.listItem, s.listItemWrapper)}>
        <ul>
          <li className={s.listItem}>
            This is not an absolute right and only applies in certain
            circumstances. If you are not satisfied with how we manage your
            data, you can contact us via email:{" "}
            <a
              href="mailto:hello@martian.ventures"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@martian.ventures
            </a>{" "}
            or you can lodge a complaint to the National Data Protection
            Authority in the Member State of your habitual residence, place of
            work or place of the alleged infringement if you consider that the
            processing of personal data relating to you infringes Regulation
            (GDPR)
          </li>
          <li className={s.listItem}>
            German DPA: Federal Commissioner for Data Protection and Freedom of
            Information (BfDI), Husarenstr. 30, 53117 Bonn, Germany
          </li>
          <li className={s.listItem}>
            Croatian DPA: Data Protection Agency, Martićeva 14, 10 000 Zagreb,
            Croatia
          </li>
        </ul>
      </li>
    </ul>
    <p className={s.paragraph}>
      If you have any questions about your rights or you want to exercise them,
      you can contact us via email{" "}
      <a
        href="mailto:hello@martian.ventures"
        target="_blank"
        rel="noopener noreferrer"
      >
        hello@martian.ventures
      </a>
      . We might ask for your additional information to verify your identity. In
      most cases, we will respond to your request within 30 days as of receipt
      of your request. We may extend the time to respond by further two months
      if the request is complex or we have received several requests from you.
      In such case, we will inform you about the reasons for delay.
    </p>
    <SectionHeading className={s.title} order="14">
      Changes to this&nbsp;Policy
    </SectionHeading>
    <p className={s.paragraph}>
      We have the right to make changes to this Policy. Any changes we make will
      be published on <Link to="/privacy-policy/">this website</Link>.
    </p>
    <p className={s.paragraph}>This Policy was last updated in May 2021.</p>
  </div>
)

export default PrivacyPolicyContent
